<template>
  <div>  
      <app-navbar8>
      </app-navbar8>
  <div class="article-container">
    <h1 class="article-title">  RacketNation.ph Officially Sponsors Elite Coach Aileen Bautista Rogan (Coach Ai)</h1>
    <p class="article-date">Published on February 24, 2025</p>
    <img
              alt="image"
              src="/assets/news/2.png"
              class="article-image"
            />
    <p class="article-content">
      RacketNation.ph is thrilled to announce its official sponsorship of Ms. Aileen Rogan, also known as Coach Ai, as RacketNation’s Elite Coach. With over 25 years of experience in the tennis world, Coach Ai has built a remarkable reputation as both a coach and competitor. Her career includes numerous local and international achievements, including singles and doubles championships. Her dedication and success in tennis have made her a prominent figure within the sport, both in the Philippines and abroad.</p>
       <p class="article-content">
        Coach Ai holds a variety of prestigious certifications that showcase her extensive expertise. She is accredited by the International Tennis Federation (ITF), which highlights her international standing. She is also certified by the Australasian Academy of Tennis Coaches (AATC) at Levels 1 and 2, as well as the Philippine Sports Commission (PSC), where she holds National Coach Certifications at Levels 1 and 2. Additionally, she is a Certified Developmental Coach with the United States Professional Tennis Association (USPTA), making her one of the most highly qualified tennis coaches in the country.</p>
    
      <p class="article-content">As part of the partnership with RacketNation, Coach Ai will be provided with the necessary training equipment to help develop young tennis talents in the Philippines. RacketNation is committed to supporting her by facilitating training camps across the nation and assisting in the scouting of emerging players. Furthermore, RacketNation will support Coach Ai in helping these athletes secure sponsorships from leading brands and explore opportunities for international tennis scholarships with top universities in the United States. This collaboration ensures that young Filipino athletes will have access to top-tier coaching and the resources they need to reach their full potential on the global stage.</p>
     <p class="article-content">In a statement, Coach Ai shared, <span class="article-comment">“I am excited to join RacketNation and contribute to the development of tennis in the Philippines. With their support, I’m confident that we can nurture young talent and give them the opportunity to compete internationally. Together, we can take Philippine tennis to new heights.”</span></p>
     <p class="article-content">Through this sponsorship, RacketNation continues to invest in the future of tennis in the Philippines, aiming to create a pipeline for talented athletes who can compete at the highest levels. With Coach Ai’s expertise and RacketNation’s support, the next generation of tennis stars is poised to thrive.</p>
     <p class="article-content">Senior Partner of RacketNation, Andrea Nava, also expressed his enthusiasm for the partnership: <span class="article-comment">“Coach Ai’s wealth of experience and passion for tennis aligns perfectly with RacketNation’s mission to elevate the sport in the Philippines. We are proud to have her as part of our team and look forward to working together to empower the next generation of tennis players.”</span></p>
     
  </div>
  <app-footer-4/>
  </div>

</template>

<script>
import AppNavbar8 from '../navbar8.vue'
import AppFooter4 from '../footer4.vue'

export default {
  name: 'CoachAi',
  props: {},
  data() {
    return {
      rawd9wf: ' ',
      raww0ed: ' ',
      rawhjnr: ' ',
    }
  },
  components: {
  AppNavbar8,
  AppFooter4,
},
  metaInfo: {
    title: 'racketnation-sponsors-coach-Ai',
    meta: [
      {
        property: 'og:title',
        content: 'racketnation-sponsors-coach-Ai',
      },
    ],
  },
}
</script>

<style>
.article-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.article-title {
  font-size: 28px;
  margin-bottom: 10px;
}

.article-date {
  color: gray;
  font-size: 14px;
  margin-bottom: 15px;
}

.article-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.article-content {
  font-size: 17px;
  line-height: 1.6;
  color: white;
  margin-bottom: 10px;
}
.article-subtitle {
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
  font-family: 'Semibold';
}
.article-comment{
  font-family: 'Italic';
  font-size: 17px;
  line-height: 1.6;
  color: white;
  margin-bottom: 10px;
}
</style>


