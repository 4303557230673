<template>
      <div id="ourstory" class="home-our-story">
        <div class="home-heading-container4">
          <h1 class="home-text28 Section-Heading">EVENTS</h1>
        </div>
        <div class="home-cards-container4">

          <div class="home-left-section">
            <div class="home-video-container">
              <iframe
              class="home-video"
                  width="560"
                  height="315"
                  :src="youtubeEmbedUrl2"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
             
            </div>
            <div class="home-content-container2">
              <span class="home-heading17 Card-Heading">
                🎾 RacketNation Exclusive: Undercard Match RAW Coverage! 🔥 </span>
              <span class="home-text30 Card-Text">
                Before the Queens Court Challengers Cup Championship Match on February 26, 2025, watch the intense undercard battle at The Palms Country Club featuring Bianca Casten & Kai Peña vs. Mafie Luancing & Lielia Bennett! 💪🏆</span>
              <span class="home-text31 Card-Text">
                No commentary—just pure, unfiltered action straight from the court! (North View) Who had the stronger net game? Who dominated the baseline rallies? Watch now and decide! 👀</span>
              <span class="home-text30 Card-Text">🏆 Proudly sponsored by: ROMA, Chingolo, and Novacon</span>

            </div>
            <div class="home-info-container1">
              <span class="Card-Text">26 February 2025</span>
              <div class="home-stats-container1">
                <div class="home-messages-container1">
                  <svg viewBox="0 0 1024 1024" class="home-icon44">
                    <path
                      d="M938.667 490.539v-21.205c0-0.725-0.043-1.621-0.085-2.475-5.803-99.755-47.488-190.336-112.768-258.176-68.352-71.125-162.731-117.419-268.843-123.264-0.64-0.043-1.493-0.085-2.304-0.085h-20.864c-59.947-0.683-122.965 13.227-181.931 43.008-52.181 26.539-97.749 63.531-133.931 108.203-56.405 69.675-89.899 158.037-89.941 253.653-0.597 54.4 10.795 111.36 35.157 165.419l-75.605 226.859c-2.816 8.363-3.072 17.835 0 26.965 7.467 22.357 31.616 34.432 53.973 26.965l226.731-75.563c49.493 22.485 105.984 35.243 165.376 35.115 58.539-0.384 115.84-13.141 168.149-36.949 81.579-37.163 151.040-101.248 193.707-186.667 27.477-53.291 43.307-115.84 43.136-181.803zM853.333 490.795c0.128 52.267-12.459 101.333-33.664 142.464-34.176 68.352-88.832 118.784-153.259 148.139-41.387 18.859-86.869 28.971-133.376 29.312-52.096 0.128-101.163-12.459-142.293-33.664-10.624-5.504-22.528-6.059-33.067-2.56l-162.261 54.101 54.101-162.261c3.755-11.221 2.56-22.912-2.389-32.725-23.552-46.72-34.304-96.213-33.792-142.464 0.043-76.331 26.411-145.877 70.912-200.917 28.629-35.328 64.768-64.725 106.283-85.76 46.592-23.552 96.085-34.304 142.336-33.792h19.456c83.712 4.565 158.037 41.003 212.011 97.109 51.285 53.376 84.139 124.416 89.003 202.837z"
                    ></path>
                  </svg>
                  <span class="Card-Text"></span>
                </div>
                <div class="home-views-container1">
                  <svg viewBox="0 0 1024 1024" class="home-icon46">
                    <path
                      d="M512 192c-223.318 0-416.882 130.042-512 320 95.118 189.958 288.682 320 512 320 223.312 0 416.876-130.042 512-320-95.116-189.958-288.688-320-512-320zM764.45 361.704c60.162 38.374 111.142 89.774 149.434 150.296-38.292 60.522-89.274 111.922-149.436 150.296-75.594 48.218-162.89 73.704-252.448 73.704-89.56 0-176.858-25.486-252.452-73.704-60.158-38.372-111.138-89.772-149.432-150.296 38.292-60.524 89.274-111.924 149.434-150.296 3.918-2.5 7.876-4.922 11.86-7.3-9.96 27.328-15.41 56.822-15.41 87.596 0 141.382 114.616 256 256 256 141.382 0 256-114.618 256-256 0-30.774-5.452-60.268-15.408-87.598 3.978 2.378 7.938 4.802 11.858 7.302v0zM512 416c0 53.020-42.98 96-96 96s-96-42.98-96-96 42.98-96 96-96 96 42.982 96 96z"
                    ></path>
                  </svg>
                  <span class="Card-Text"></span>
                </div>
              </div>
            </div>
        
            <div class="home-video-container">
              <iframe
              class="home-video"
                  width="560"
                  height="315"
                  :src="youtubeEmbedUrl"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
             
            </div>
            <div class="home-content-container2">
              <span class="home-heading17 Card-Heading">
                🏆 Challengers Cup – Kings’ Court Match Recap 🎾🔥 </span>
              <span class="home-text30 Card-Text">
                Relive the action from the first-ever Challengers Cup Kings’ Court Match, where Niño Alcantara & Nico Bolzico faced off against Roland Kraut & Ralph Santos in an intense and thrilling battle! 💥 </span>
              <span class="home-text31 Card-Text">
                This quick recap takes you through every point, showcasing the incredible rallies, clutch winners, and high-energy moments that made this match unforgettable. From Niño’s masterful court control to Nico’s sudden-death stunners, this was a showdown to remember! 🏆</span>
              
            </div>
            <div class="home-info-container1">
              <span class="Card-Text">22 January 2025</span>
              <div class="home-stats-container1">
                <div class="home-messages-container1">
                  <svg viewBox="0 0 1024 1024" class="home-icon44">
                    <path
                      d="M938.667 490.539v-21.205c0-0.725-0.043-1.621-0.085-2.475-5.803-99.755-47.488-190.336-112.768-258.176-68.352-71.125-162.731-117.419-268.843-123.264-0.64-0.043-1.493-0.085-2.304-0.085h-20.864c-59.947-0.683-122.965 13.227-181.931 43.008-52.181 26.539-97.749 63.531-133.931 108.203-56.405 69.675-89.899 158.037-89.941 253.653-0.597 54.4 10.795 111.36 35.157 165.419l-75.605 226.859c-2.816 8.363-3.072 17.835 0 26.965 7.467 22.357 31.616 34.432 53.973 26.965l226.731-75.563c49.493 22.485 105.984 35.243 165.376 35.115 58.539-0.384 115.84-13.141 168.149-36.949 81.579-37.163 151.040-101.248 193.707-186.667 27.477-53.291 43.307-115.84 43.136-181.803zM853.333 490.795c0.128 52.267-12.459 101.333-33.664 142.464-34.176 68.352-88.832 118.784-153.259 148.139-41.387 18.859-86.869 28.971-133.376 29.312-52.096 0.128-101.163-12.459-142.293-33.664-10.624-5.504-22.528-6.059-33.067-2.56l-162.261 54.101 54.101-162.261c3.755-11.221 2.56-22.912-2.389-32.725-23.552-46.72-34.304-96.213-33.792-142.464 0.043-76.331 26.411-145.877 70.912-200.917 28.629-35.328 64.768-64.725 106.283-85.76 46.592-23.552 96.085-34.304 142.336-33.792h19.456c83.712 4.565 158.037 41.003 212.011 97.109 51.285 53.376 84.139 124.416 89.003 202.837z"
                    ></path>
                  </svg>
                  <span class="Card-Text"></span>
                </div>
                <div class="home-views-container1">
                  <svg viewBox="0 0 1024 1024" class="home-icon46">
                    <path
                      d="M512 192c-223.318 0-416.882 130.042-512 320 95.118 189.958 288.682 320 512 320 223.312 0 416.876-130.042 512-320-95.116-189.958-288.688-320-512-320zM764.45 361.704c60.162 38.374 111.142 89.774 149.434 150.296-38.292 60.522-89.274 111.922-149.436 150.296-75.594 48.218-162.89 73.704-252.448 73.704-89.56 0-176.858-25.486-252.452-73.704-60.158-38.372-111.138-89.772-149.432-150.296 38.292-60.524 89.274-111.924 149.434-150.296 3.918-2.5 7.876-4.922 11.86-7.3-9.96 27.328-15.41 56.822-15.41 87.596 0 141.382 114.616 256 256 256 141.382 0 256-114.618 256-256 0-30.774-5.452-60.268-15.408-87.598 3.978 2.378 7.938 4.802 11.858 7.302v0zM512 416c0 53.020-42.98 96-96 96s-96-42.98-96-96 42.98-96 96-96 96 42.982 96 96z"
                    ></path>
                  </svg>
                  <span class="Card-Text"></span>
                </div>
              </div>
            </div>
          </div>
          </div>

       


            <!-- Modal Overlay -->
            <div v-if="news1" class="modal-overlay" @click="closeModal">
              <div class="modal-content" @click.stop>
                <!-- Close Button -->
              <span class="close-button" @click="closeModal">&times;</span>

              <!-- Image -->
              <p class="modal-title"> RacketNation.ph Officially Sponsors Elite Coach Aileen Bautista Rogan (Coach Ai)</p>
                <p class="modal-text">February 6, 2025</p>

              <!-- Title -->
              <p class="modal-title"> Continuation.....</p>

              <!-- Paragraphs -->
              <p class="modal-text">
                As part of the partnership with RacketNation, Coach Ai will be provided with the necessary training equipment to help develop young tennis talents in the Philippines. RacketNation is committed to supporting her by facilitating training camps across the nation and assisting in the scouting of emerging players. Furthermore, RacketNation will support Coach Ai in helping these athletes secure sponsorships from leading brands and explore opportunities for international tennis scholarships with top universities in the United States. This collaboration ensures that young Filipino athletes will have access to top-tier coaching and the resources they need to reach their full potential on the global stage.

              </p>
              <p class="modal-text">
                In a statement, Coach Ai shared, “I am excited to join RacketNation and contribute to the development of tennis in the Philippines. With their support, I’m confident that we can nurture young talent and give them the opportunity to compete internationally. Together, we can take Philippine tennis to new heights.”
              </p>
              <p class="modal-text">
                Through this sponsorship, RacketNation continues to invest in the future of tennis in the Philippines, aiming to create a pipeline for talented athletes who can compete at the highest levels. With Coach Ai’s expertise and RacketNation’s support, the next generation of tennis stars is poised to thrive.
              </p>
              <p class="modal-text">
                Senior Partner of RacketNation, Danniel Bryan Dee Nava, also expressed his enthusiasm for the partnership: “Coach Ai’s wealth of experience and passion for tennis aligns perfectly with RacketNation’s mission to elevate the sport in the Philippines. We are proud to have her as part of our team and look forward to working together to empower the next generation of tennis players.”</p>
            
                <button class="open-button" @click="closeModal">Close</button>
              </div>

           
          </div>
        
      </div>

      

  </template>
  
  <script>


  export default {
    name: 'AppEvents',
    props: {},
    data() {
      return {
       
        rawd9wf: ' ',
        raww0ed: ' ',
        rawhjnr: ' ',
        news1: false,

        imageSrc: "https://via.placeholder.com/600x400", // Replace with your image URL
         videoId: "Uv591cDdAt0",
         videoId2: "31Iw-LSZCdQ"
      }
    },
    components: {
     
  },
  computed: {
    youtubeEmbedUrl2(){
      return `https://www.youtube.com/embed/${this.videoId2}`;
    },
    youtubeEmbedUrl() {
      return `https://www.youtube.com/embed/${this.videoId}`;
    },
  },
  methods:{
    readMore1(){
      
    },
   
    closeModal() {
      this.news1 = false;
    },
  },
    metaInfo: {
      title: 'Home',
      meta: [
        {
          property: 'og:title',
          content: 'Home',
        },
      ],
    },
  }
  </script>
  
  <style scoped>
 
  .home-our-story {
    width: 100%;
    height: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxcontent);
    align-items: center;
    /* margin-top: var(--dl-space-space-fiveunits); */
    /* padding-top: var(--dl-space-space-tenunits); */
    padding-left: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
    /* padding-bottom: var(--dl-space-space-fiveunits); */
  }
  .home-heading-container4 {
    width: 45%;
    display: flex;
    align-items: center;
    padding-left: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .home-text28 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-text29 {
    color: var(--dl-color-gray-white);
    text-align: center;
  }
  .home-cards-container4 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-doubleunit);
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-left-section {
    /* width: 50%; */
    display: flex;
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    margin-right: var(--dl-space-space-fourunits);
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
  }
  .home-video-container {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--dl-color-gray-900);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-video {
    width: 100%;
    height: 350px;
    position: relative;
    border-radius: var(--dl-radius-radius-radius8);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-play-container {
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    right: auto;
    width: 50px;
    bottom: auto;
    height: 50px;
    display: flex;
    position: absolute;
    align-items: center;
    border-color: var(--dl-color-gray-500);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-round);
    flex-direction: column;
    justify-content: center;
  }
  .home-icon42 {
    fill: var(--dl-color-gray-500);
    width: 48px;
    height: 48px;
  }
  .home-content-container2 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: flex-start;
  }
  .home-heading17 {
    text-align: left;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text30 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text31 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-info-container1 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
    justify-content: space-between;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-stats-container1 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .home-messages-container1 {
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
  }
  .home-icon44 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-views-container1 {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-icon46 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-right-section {
    /* width: 50%; */
    display: flex;
    /* flex-wrap: wrap; */
    align-self: stretch;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: row;
    justify-content: space-between;
    
  }
  .home-card1 {
    width: 48%;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: column;
    justify-content: space-between;
  }
  .home-image3 {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-radius8);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-content-container3 {
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;

  }
  .home-text34 {
    text-align: left;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-info-container2 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
    justify-content: space-between;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-stats-container2 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .home-messages-container2 {
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
  }
  .home-icon48 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-views-container2 {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-icon50 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-card2 {
    width: 48%;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: column;
    justify-content: space-between;
  }
  .home-image4 {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-radius8);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-content-container4 {
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .home-text37 {
    text-align: left;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-info-container3 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
    justify-content: space-between;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-stats-container3 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .home-messages-container3 {
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
  }
  .home-icon52 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-views-container3 {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-icon54 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-card3 {
    width: 48%;
    height: 500px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .home-image5 {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-radius8);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-content-container5 {
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .home-text40 {
    text-align: left;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-info-container4 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
    justify-content: space-between;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-stats-container4 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .home-messages-container4 {
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
  }
  .home-icon56 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-views-container4 {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-icon58 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-card4 {
    width: 48%;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    justify-content: space-between;
  }
  .home-image6 {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-radius8);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-content-container6 {
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .home-text43 {
    text-align: left;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-info-container5 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-doubleunit);
    justify-content: space-between;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-stats-container5 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .home-messages-container5 {
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-tripleunit);
    flex-direction: row;
  }
  .home-icon60 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-views-container5 {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-icon62 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-section-separator3 {
    width: 100%;
    height: 1px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
  }
  
  @media(max-width: 991px) {
    .home-hero {
      flex-direction: column-reverse;
    }
    .home-content-container1 {
      flex-direction: column-reverse;
    }
    .home-hero-text {
      width: 80%;
    }
    .home-cards-container3 {
      justify-content: center;
    }
    .home-cards-container4 {
      flex-direction: column;
    }
    .home-left-section {
      width: 100%;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-fourunits);
    }
    .home-right-section {
      width: 100%;
    }
    .home-form-container {
      width: 60%;
    }
    .home-locations-container {
      flex-wrap: wrap;
    }
    .home-location1 {
      margin-bottom: var(--dl-space-space-tripleunit);
    }
  }
  @media(max-width: 767px) {
    .home-burger-menu {
      display: flex;
      padding: var(--dl-space-space-halfunit);
      background-color: var(--dl-color-gray-black);
    }
    .home-icon10 {
      fill: var(--dl-color-gray-white);
    }
    .home-links-container1 {
      display: none;
    }
    .home-heading-container1 {
      width: 100%;
    }
    .home-cards-container1 {
      align-items: center;
      flex-direction: column;
    }
    .home-features-card1 {
      width: 70%;
      margin-bottom: var(--dl-space-space-tripleunit);
    }
    .home-features-card2 {
      width: 70%;
      margin-bottom: var(--dl-space-space-tripleunit);
    }
    .home-features-card3 {
      width: 70%;
      margin-bottom: var(--dl-space-space-tripleunit);
    }
    .home-features-card4 {
      width: 70%;
      margin-bottom: var(--dl-space-space-tripleunit);
    }
    .home-features-card5 {
      width: 70%;
      margin-bottom: var(--dl-space-space-tripleunit);
    }
    .home-features-card6 {
      width: 70%;
      margin-bottom: var(--dl-space-space-tripleunit);
    }
    .home-heading-container2 {
      width: 100%;
    }
    .home-cards-container2 {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
    }
    .home-service-card1 {
      width: 65%;
      margin-bottom: var(--dl-space-space-fourunits);
    }
    .home-service-card2 {
      width: 65%;
      margin-bottom: var(--dl-space-space-fourunits);
    }
    .home-service-card3 {
      width: 65%;
    }
    .home-heading-container3 {
      width: 100%;
    }
    .home-heading-container4 {
      width: 100%;
    }
    .home-cards-container4 {
      align-items: center;
      flex-direction: column;
    }
    .home-content-container7 {
      width: 100%;
      flex-direction: column;
    }
    .home-form-container {
      width: 100%;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-fourunits);
    }
    .home-locations-container {
      flex-direction: row;
      justify-content: center;
    }
  }
  @media(max-width: 479px) {
    .home-cta-btn1 {
      font-size: 16px;
      padding-left: var(--dl-space-space-doubleunit);
      padding-right: var(--dl-space-space-doubleunit);
    }
    .home-hero {
      padding-top: var(--dl-space-space-fourunits);
      padding-bottom: var(--dl-space-space-fourunits);
    }
    .home-content-container1 {
      padding-top: var(--dl-space-space-fiveunits);
      padding-bottom: var(--dl-space-space-fiveunits);
    }
    .home-hero-text {
      width: 100%;
    }
    .home-features {
      padding-top: var(--dl-space-space-fiveunits);
      padding-bottom: var(--dl-space-space-fiveunits);
    }
    .home-features-card1 {
      width: 100%;
    }
    .home-features-card2 {
      width: 100%;
    }
    .home-features-card3 {
      width: 100%;
    }
    .home-features-card4 {
      width: 100%;
    }
    .home-features-card5 {
      width: 100%;
    }
    .home-features-card6 {
      width: 100%;
    }
    .home-services {
      padding-top: var(--dl-space-space-fiveunits);
      padding-bottom: var(--dl-space-space-fiveunits);
    }
    .home-service-card1 {
      width: 100%;
    }
    .home-service-card2 {
      width: 100%;
    }
    .home-service-card3 {
      width: 100%;
    }
    .home-team {
      padding-top: var(--dl-space-space-fiveunits);
      padding-bottom: var(--dl-space-space-fiveunits);
    }
    .home-text26 {
      text-align: center;
    }
    .home-our-story {
      /* padding-top: var(--dl-space-space-fiveunits); */
    }
    .home-text28 {
      text-align: center;
    }
    .home-video {
      height: 200px;
    }
    .home-card1 {
      width: 100%;
    }
    .home-card2 {
      width: 100%;
    }
    .home-card3 {
      width: 100%;
    }
    .home-card4 {
      width: 100%;
    }
    .home-content-container7 {
      padding-left: 0px;
    }
    .home-form-container {
      padding: var(--dl-space-space-tripleunit);
      margin-right: 0px;
    }
    .home-heading18 {
      text-align: center;
    }
    .home-location1 {
      margin-right: 0px;
      margin-bottom: 0px;
    }
  }

  /* Button Styles */
.open-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.open-button:hover {
  background-color: #0056b3;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  height: 80vh; /* Set height to enable scrolling */
  width: 90%;
  max-width: 500px;
  text-align: left;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  font-family: 'Light';
  font-size: 14px;
  overflow: scroll;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  bottom: 0 !important;
}

/* Image */
.modal-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

/* Title */
.modal-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: black;
}

/* Paragraphs */
.modal-text {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}
  </style>
  